import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config } from './lib/config/Config';
import { ApplicationService } from './lib/services/application.service';
import { GlobalErrorHandler } from './lib/services/global-error-handler.service';
import { HttpRequestService } from './lib/services/http-request.service';
import { LogService } from './lib/services/log.service';
import { UtenteService } from './lib/services/utente.service';
import { LibModule } from './lib/lib.module';
import { MobileModule } from './mobile/mobile.module';
import { PortaleModule } from './portale/portale.module';

import { provideFirebaseApp, initializeApp as initializeApp_alias } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp } from "firebase/app";
import { environment } from '../environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

// init firebase
// @ts-ignore
// const firebaseApp = initializeApp(environment.config.pwa.firebaseConfig);

/**
 * Utilità per loading applicazione
 *
 * 1. Caricamento configurazione
 * 2. Caricamento applicazione
 *
 * @param httpClient HttpClient
 * @param httpRequestService: HttpRequestService
 * @param applicationService: ApplicationService
 *
 */
export function initApplication(httpClient: HttpClient, httpRequestService: HttpRequestService, logService: LogService, applicationService: ApplicationService) {
  return () => {
    return Config.getInstance().loadConfig(httpClient, environment)
      .then(config => {
        return applicationService
      })
      .then(() => {
      });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule,
    PortaleModule, FormsModule, LibModule,
    BrowserAnimationsModule, MatAutocompleteModule, FormsModule, MatFormFieldModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // non registro il sw su safari
      enabled: true,
      // solo registerWhenStable non permette la registrazione del sw, forse un bug di angular
      registrationStrategy: 'registerWhenStable:3000'
    }),
    MobileModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    // provideFirebaseApp(() => firebaseApp),
    // provideAuth(() => getAuth()),
  ],
  exports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [
        HttpClient,
        HttpRequestService,
        ApplicationService
      ],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    /*{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },*/
    provideFirebaseApp(() => initializeApp({ "projectId": "salute-con-te", "appId": "1:220709521591:web:77a3bc00d120ad9287a532", "storageBucket": "salute-con-te.firebasestorage.app", "apiKey": "AIzaSyAeSzKU930W9fdPiUfsvQEM-JSXy6QHb1w", "authDomain": "salute-con-te.firebaseapp.com", "messagingSenderId": "220709521591", "measurementId": "G-DYVFXPWV3J" })),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

