import { Injectable } from '@angular/core';
import { ReplaySubject, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpRequestResponse } from '../../lib/models/http-request-response.model';
import { HttpRequestService } from '../../lib/services/http-request.service';
import { LogService } from '../../lib/services/log.service';
import { UtenteService } from '../../lib/services/utente.service';

import { Router } from '@angular/router';
import { FunzionalitaService } from '../../lib/services/funzionalita.service';
import { newError } from '../../lib/utils/Utility';
import { Notifica } from '../types/Notifica';

@Injectable({
  providedIn: 'root'
})
export class ComunicazioniService {

  public lastComunicationsCount = new ReplaySubject<number>(1)

  constructor(
    private httpRequestService: HttpRequestService,
    private logService: LogService,
    private utenteService: UtenteService,
    private funzionalitaService: FunzionalitaService,
    public router: Router
  ) { }

  inviaComunicazione(notifica: Partial<Notifica>) {
    const url = '/siev_mnotifiche?token=' + this.utenteService.getToken();
    return from(this.httpRequestService.sendFile(url, { notifica }));
  }

  estraiAlberoComunicazione(id: number) {
    return this.httpRequestService.get('/notifica/risposte-tutte-sct', { id })
      .pipe(
        map(response => {
          if (HttpRequestResponse.isSuccessResponse(response)) {
            return response.data.rows;
          } else {
            this.logService.messageError('Errore', response.message, newError(response.message));
            return null;
          }
        })
      )
  }

  comunicazioneRicorsivaReinvia(id: string) {
    return this.impostaFlagComunicazioneRicorsiva(id, 'S');
  }

  comunicazioneRicorsivaIgnora(id: string) {
    return this.impostaFlagComunicazioneRicorsiva(id, 'N');
  }

  private impostaFlagComunicazioneRicorsiva(id: string, reinvia: string) {
    return this.httpRequestService.post('/notifica/farmaco/reinvia', { id_notifica: id, reinvia })
      .pipe(
        map(response => {
          if (HttpRequestResponse.isSuccessResponse(response)) {
            return response;
          } else {
            this.logService.messageError('Errore', response.message, newError(response.message));
            return null;
          }
        })
      );
  }

  impostaNotificaLetta(id: number, showSpinner: boolean = false) {
    return this.httpRequestService.post('/siev_fsetview_notifica', { id: id }, {}, showSpinner)
      .pipe(
        map(response => {
          if (HttpRequestResponse.isSuccessResponse(response)) {
            return response;
          } else {
            this.logService.messageError('Errore', response.message, newError(response.message));
            return null;
          }
        })
      );
  }
}
