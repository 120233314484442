import { Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { environment } from "../../../environments/environment";
import { getDeviceId } from "../../mobile/utility-mobile";
import { DialogMaterialService } from "../services/dialog-material.service";
import { HttpRequestService } from "../services/http-request.service";
import { LogService } from "../services/log.service";
import { toJson } from "../utils/Utility";
import { SwUpdateService } from "./sw-update.service";
import { deleteToken, getMessaging, getToken, isSupported, Messaging, onMessage } from "@angular/fire/messaging";

export enum FNAskPermissionEnum {
    AlreadyGranted = 1,
    AlreadyDeniedByUser,
    OkAsk,
    DeniedByUser,
    NotificationsNotSupported,
    UnknownError,
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseNotificationsService {

    private messaging
    private currentToken

    constructor(
        private router: Router,
        private swUpdate: SwUpdateService,
        private http: HttpRequestService,
        private log: LogService,
        private dms: DialogMaterialService,
        @Optional() messaging: Messaging
    ) {
        if(messaging) {
            this.messaging = messaging
        }
    }

    async checkNotificationObject() {
        return await isSupported()
    }
    notificationGranted() {
        return Notification.permission === 'granted'
    }
    notificationDenied() {
        return Notification.permission === 'denied'
    }

    /**
     * Inizializza firebase. "Funziona" solo se l'utente ha già una sottoscrizione attiva / se ha
     * già dato le autorizzazioni
     */
    init() {
        // se le notifiche sono supportate e non è stato negato
        this.checkNotificationObject().then(yes => {
            if (yes && this.notificationGranted()) {
                this.getToken().then(() => {
                    this.listen()
                })
            }
        })
    }

    /**
     * Se possibile mostra il messaggio pre-richiesta autorizzazione.
     * L'autorizzazione vera e propria la richiede firebase con la getToken()
     * @returns
     */
    async showMessageAskPermission(): Promise<FNAskPermissionEnum> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!(await this.checkNotificationObject())) {
                    this.swUpdate.addLog("Notification object not present")
                    this.log.messageWarning("Attenzione", "Le notifiche non sono supportate da questo  dispositivo")
                    resolve(FNAskPermissionEnum.NotificationsNotSupported)
                    return
                }

                // se è giù stato dato il permesso
                if (this.notificationGranted()) {
                    this.swUpdate.addLog('Notifications permission already granted')
                    resolve(FNAskPermissionEnum.AlreadyGranted)
                    return
                }

                // se è già stato negato non procedo
                if (this.notificationDenied()) {
                    this.swUpdate.addLog('Notifications permission already denied')
                    resolve(FNAskPermissionEnum.AlreadyDeniedByUser)
                    return
                }

                this.swUpdate.addLog('Requesting notifications permission by modal')

                lastValueFrom(this.dms.openYesNo(
                    "Attenzione",
                    "Vuole ricevere le notifiche direttamente sul suo dispositivo? In caso affermativo acconsentire al messaggio che verrà mostrato.",
                    {
                        disableClose: true
                    })
                    .afterClosed()
                ).then(value => {
                    if (value) {
                        this.swUpdate.addLog("Yes to ask notifications permissions by modal")
                        resolve(FNAskPermissionEnum.OkAsk)
                    } else {
                        this.swUpdate.addLog("No to ask notifications permissions by modal")
                        resolve(FNAskPermissionEnum.DeniedByUser)
                    }
                })
            } catch (err) {
                resolve(FNAskPermissionEnum.UnknownError)
            }
        })
    }

    /**
     * Estrae un token firebase e lo invia al backend
     * @returns
     */
    private async getToken() {
        let currentToken = ''

        try {
            // la getToken() di firebase richiede in autonomia i permessi
            currentToken = await getToken(this.messaging, { vapidKey: environment.config.pwa.vapidPublicKey })

            if (currentToken) {
                this.swUpdate.addLog(`Got Firebase token: ${currentToken}`)
                this.currentToken = currentToken

                await this.sendTokenToServer(currentToken)
            } else {
                this.swUpdate.addLog('No registration token available. Request permission to generate one.');
            }
        } catch (err) {
            this.swUpdate.addLog(`An error occurred while retrieving token. ${err.message}`);
        }

        return currentToken
    }

    /**
     * Si mette in ascolto delle notifiche in foreground
     */
    listen() {
        const messaging = getMessaging()

        onMessage(messaging, (payload) => {
            this.swUpdate.addLog(`Message received. ${toJson(payload)}`)

            this.renderMessage(payload)
        })
    }

    /**
     * Mostra una nuova comunicazione in arrivo quando l'app è aperta
     * @param payload
     */
    private renderMessage(payload) {
        const snack = this.log.snack(payload?.notification?.title, "Vai alle comunicazioni", 30)

        snack.afterDismissed().subscribe(value => {
            if (value.dismissedByAction) {
                if(payload?.fcmOptions?.link) {
                    this.router.navigateByUrl(payload?.fcmOptions?.link)
                } else {
                    this.router.navigate(['/', 'comunicazioni', 'comunicazioni'])
                }
            }
        })
    }

    /**
     * Invia una nuova sub al server per essere registrata
     */
    private async sendTokenToServer(token: string) {
        return lastValueFrom(
            this.http.post(
                '/mobile/push-notifications/register',
                {
                    dispositivo: getDeviceId(),
                    subscription: token
                },
                {},
                false
            ))
    }

    /**
     * Clear the current subscription
     */
    async clearSubscription() {

        console.log('--> MESSAGING: ', this.messaging)
        console.log('--> currentToken: ', this.currentToken)

        try {
            await deleteToken(this.messaging)
            this.swUpdate.addLog(`Cleared firebase token`)
        } catch (err) {
            this.swUpdate.addLog(`Error clearing subscription from browser:<span class="error-text">${err.message}</span>`)
        }

        return await lastValueFrom(this.http.delete(
            '/mobile/push-notifications/',
            {
                subscription: this.currentToken
            },
            {},
            false
        ))
    }

    /**
     * Da il via al processo di richiesta abilitazione alle comunicazioni
     */
    async startRequestProcess() {
        const token = await this.getToken()

        if (token.length > 0) {
            this.listen()
        }
    }

    /**
     * Mostro il messaggio informativo per andare nelle comunicazioni.
     * Se le comunicazioni sono supportate e se sono in stato 'default' chiedo ad oltranza
     * fin quando l'utente non "ha capito"
     */
    async showInfoComunications() {
        if (await this.checkNotificationObject()) {
            if (!this.notificationGranted() && !this.notificationDenied()) {
                const alreadyAsked = localStorage.getItem('saluteconte.notifications-info-asked')
                if (alreadyAsked !== 'S') {
                    const result = await lastValueFrom(
                        this.log.snack(
                            "Se vuoi abilitare le notifiche vai nelle impostazioni che trovi nel menù laterale",
                            "Ho capito",
                            300
                        ).afterDismissed()
                    )
                    if (result.dismissedByAction) {
                        localStorage.setItem('saluteconte.notifications-info-asked', 'S')
                    }
                }
            }
        }
    }

    async askToUser() {
        // 10. comunica all'utente che gli stiamo per richiedere il permesso alle notifiche
        const askResult = await this.showMessageAskPermission()

        // 20. se ho già chiesto mi metto solo in ascolto
        if (askResult === FNAskPermissionEnum.AlreadyGranted) {
            this.log.messageInfo("Attenzione", "Notifiche già abilitate")
        }
        // 21: se l'utente non accetta non faccio nulla
        else if (askResult === FNAskPermissionEnum.DeniedByUser) {
        }
        // 22. se le notifiche non sono supportate non faccio nulla
        else if (askResult === FNAskPermissionEnum.NotificationsNotSupported) {
        }
        // 23. in caso di errore sconosciuto non faccio nulla
        else if (askResult === FNAskPermissionEnum.UnknownError) {
        }
        // 24. se l'utente in passato ha negato il permesso glielo comunico
        else if (askResult === FNAskPermissionEnum.AlreadyDeniedByUser) {
            this.log.messageWarning("Attenzione", "Le notifiche sono state bloccate in precedenza. Per questioni di sicurezza è possibile riabilitarle solo reinstallando l'app.")
        }
        // 24. se l'utente accetta gli chiedo l'autorizzazione
        else {
            this.startRequestProcess()
        }
    }
}
